import { PHASE_PRODUCTION_BUILD } from 'next/constants'

import { Post } from 'src/components/Post/Post'
import { config } from 'src/config'
import { POST_TYPE_GUIDE, POST_TYPE_PAGE, POST_TYPE_PRODUCT_TEST } from 'src/constants'
import { fetchPost } from 'src/data/providers/fetchPost'
import { fetchPosts } from 'src/data/providers/fetchPosts'
import { getLanguageFromLocale, getLocaleFromLanguage } from 'src/functions/i18n/localeFns'
import { getCache } from 'src/lib/keyv'

import type { GetStaticPaths, GetStaticPathsResult, GetStaticProps } from 'next'
import type { Language } from 'src/enum/Language'
import type { Locale } from 'src/enum/Locale'
import type { Post as IPost } from 'src/types/Post'

const getStaticProps: GetStaticProps = async ({ params }) => {
  const { lang, slug } = params as { lang: Language; slug: string }
  const locale = getLocaleFromLanguage(lang)
  const cache = getCache('pages')

  /**
   * /e/ pages can be added in wordpress in 3 different ways: as pages, guides or product tests.
   * The shitty thing is, all these types share the same URL in shopware (/e/ pages), but they can be located
   * in 3 different places in Wordpress. So we have to try up to 3 different API calls to find the right one.
   * First we start with pages, because thats the most common page "type". If not found, try the others until we find XD
   * This is a crappy solution.... BUT, this function only runs DURING DEVELOPMENT. During export, everything is already
   * cached, so this function is not called. And in prod, well, we already have the pregenerated html pages there.
   */
  let post: IPost | null | undefined = await cache.get(`${slug}.${locale}`)
  if (!post) post = await fetchPost(POST_TYPE_PAGE, slug, locale)
  if (!post) post = await fetchPost(POST_TYPE_GUIDE, slug, locale)
  if (!post) post = await fetchPost(POST_TYPE_PRODUCT_TEST, slug, locale)

  if (!post) {
    return { notFound: true }
  }

  return { props: { post, type: 'content' } }
}

const getStaticPaths: GetStaticPaths = async () => {
  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    const paths: GetStaticPathsResult['paths'] = []
    const cache = getCache('pages')

    await Promise.all(
      config.locales.map(async (locale) => {
        const pages = await fetchPosts(POST_TYPE_PAGE, locale as Locale)
        const tests = await fetchPosts(POST_TYPE_PRODUCT_TEST, locale as Locale)
        const guides = await fetchPosts(POST_TYPE_GUIDE, locale as Locale)
        const lang = getLanguageFromLocale(locale as Locale)

        pages.concat(tests, guides).forEach((post) => {
          if (post.slug) {
            cache.set(`${post.slug}.${locale}`, post)
            paths.push({
              params: {
                slug: post.slug,
                lang,
              },
            })
          }
        })
      }),
    )

    return {
      paths,
      fallback: false,
    }
  }

  return {
    paths: [],
    fallback: 'blocking',
  }
}

export { getStaticProps, getStaticPaths }

export default Post
